import LogInView from "../../views/LogInView.vue";
import LoggedInView from "../../views/LoggedInView.vue";
import UploadView from "../../views/Upload.vue";
import NotAllowed from "../../views/NotAllowed.vue";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/login",
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: LogInView,
    name: "Login",
    meta: {
      requiresAuth: false,
    }, 
  },
  {
    path: "/token/:token",
    component: LogInView,
    name: "Token",
    meta: {
      requiresAuth: false,
    }, 
  },
  {
    path: "/home",
    name: "Home",
    component: LoggedInView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/notallowed",
    name: "NotAllowed",
    component: NotAllowed,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/upload",
    name: "Upload",
    component: UploadView,
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
