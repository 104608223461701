// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { 
  faSignOut,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons'

import { 
  faSortAlt
} from '@fortawesome/pro-duotone-svg-icons'

import { 
  faTimes
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faSignOut,
  faSortAlt,
  faTimes,
  faExclamationTriangle
)
export{ FontAwesomeIcon }
