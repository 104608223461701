import { createApp } from 'vue'
import App from './App.vue'

import router from "./plugins/router";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import "@comlinedev/bootstrap5/dist/css/bootstrap.min.css";
import "@comlinedev/bootstrap5/dist/js/bootstrap.bundle.min.js";

import { FontAwesomeIcon } from "./plugins/fontawsome";

import { auth } from "./plugins/firebase";
import { onAuthStateChanged } from "firebase/auth";
import store from "./plugins/store";

let app

onAuthStateChanged(auth, (user) => {
  store.dispatch("fetchUser", user ? user : false)
  
  if (!app) {
    app = createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('Datepicker', Datepicker)
    .provide('$router', router)
    .use(router)
    .use(store)
    .mount('#app')
  }

})

app