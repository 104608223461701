<template>
  <div id="naviFrame" class="bg-primary" >
    <div class="container">
      <ul id="navi">
        <li id="headline">
          <div class="d-flex align-self-center me-2">
            <logo-component
              :fill="'#ffffff'"
              :height="'33px'"
              :width="'33px'"
              class="logo"
              />
          </div>
          <h1>Summary Invoice Comparison</h1>
        </li>
        <li><router-link to="/home">Tabelle</router-link></li>
        <li><router-link to="/upload">Upload</router-link></li>
        <li id="logOut">
          <a href="#" style="font-size: 22px !important" @click="logout()">
            <font-awesome-icon :icon="['fas', 'sign-out']" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import LogoComponent from "./LogoComponent.vue";
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "MyNavi",
  components: {
    LogoComponent,
  },
  methods:{
    logout(){
      signOut(getAuth()).then(() => {
        this.$router.push('login')
      })
    }
  }

};
</script>

<style>
.container {
  width: 1140px;
  margin: auto;
}
#naviFrame {
  width: 100vw;
  height: 59px;
  border-bottom: 1px solid lightgray;
  display: flex;
  align-content: center;
}
#navi {
  width: 100%;
  height: 60px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding-left: 0;
  margin: 0;
}
#headline {
  display: flex;
  align-items: center;
  height: 60px;
  margin-right: 35px;
}
#headline h1 {
  font-size: 1.1rem;
  color: #ffffff;
  width: 237px;
}
#navi a {
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  margin-right: 20px;
}
#navi a:hover {
  color: #c4c4c4;
  text-decoration: underline;
}
#logOut {
  margin-left: auto;
}
</style>