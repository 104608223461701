import {createStore} from "vuex";

export default createStore({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
  },
  getters: {
    user(state){
      return state.user
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user ? user : false);
      if (user) {
        commit("SET_USER", user ? user : false);
      } else {
        commit("SET_USER", false);
      }
    },
  }
});