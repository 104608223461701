<template>
  <div class="container"> 
    <div class="col">
      <div class="d-flex justify-content-between mt-2 mb-2">
        <div>
          Letzter Upload: 
          <template v-if="updates.si">
            {{updates.si.toDate().toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day:'2-digit' })}}
            {{updates.si.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})}} Uhr
          </template>
          <template v-else>
            <Loading :size="'15px'" :msg="'false'"/>
          </template>
        </div>
        <div>
          Letze Aktualisierung des Wareneingangs: 
          <template v-if="updates.we">
            {{updates.we.toDate().toLocaleDateString('de-DE', { year: '2-digit', month: '2-digit', day:'2-digit' })}}
            {{updates.we.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'})}} Uhr
          </template>
          <template v-else>
            <Loading :size="'15px'" :msg="'false'"/>
          </template>
        </div>
      </div>
    </div>
  </div>
  <div class="col m-2">
    <div class="custombox">
      <div class="d-flex sticky-top" style="background-color: white; padding: 20px;">
        <div class="input-group"> 
          <Datepicker v-model="date" range @update:modelValue="reloadDate()" :format="'dd.MM.yy'" :enableTimePicker="false" locale="de-DE" selectText="Auswählen" cancelText="Schließen"/>
          <input type="text" class="form-control" placeholder="Suchen" aria-label="search" v-model="searchData" @keyup.enter="this.useSearch = searchData">
          <button type="button" class="btn btn-outline-secondary" style="border:1px solid #ced4da;" data-bs-dismiss="modal" @click="searchData = ''; useSearch = '';"><font-awesome-icon :icon="['fal', 'times']" /></button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="this.useSearch = searchData">Suchen</button>
        </div>
      </div>
      <table class="table mb-0" v-if="filteredData">
        <thead style="background-color: white; position: sticky; top: 78px;">
          <tr>
            <th>Shop</th>
            <th>Typ</th>
            <th scope="col" @click="switchSort('singleprice')" >Einzelpreis (WE) <font-awesome-icon v-if="sortThis.includes('singleprice')" :icon="['fad', 'sort-alt']" :class="sortThis.includes('Down') && `fa-rotate-180`" /></th>
            <th scope="col" @click="switchSort('quantity')" >Menge (WE) <font-awesome-icon :icon="['fad', 'sort-alt']" v-if="sortThis.includes('quantity')" :class="sortThis.includes('Down') && `fa-rotate-180`"/></th>
            <th scope="col" @click="switchSort('calcCost')" >Gesamtpreis (WE) <font-awesome-icon :icon="['fad', 'sort-alt']" v-if="sortThis.includes('calcCost')" :class="sortThis.includes('Down') && `fa-rotate-180`"/></th>
            <th scope="col" @click="switchSort('sPrice')" >Einzelpreis (SI) <font-awesome-icon :icon="['fad', 'sort-alt']" v-if="sortThis.includes('sPrice')" :class="sortThis.includes('Down') && `fa-rotate-180`"/></th>
            <th scope="col" @click="switchSort('sqty')" >Menge (SI) <font-awesome-icon :icon="['fad', 'sort-alt']" v-if="sortThis.includes('sqty')" :class="sortThis.includes('Down') && `fa-rotate-180`"/></th>
            <th scope="col" @click="switchSort('sExPrice')" >Gesamtpreis (SI) <font-awesome-icon :icon="['fad', 'sort-alt']" v-if="sortThis.includes('sExPrice')" :class="sortThis.includes('Down') && `fa-rotate-180`"/></th>
            <th>Preisabweichung</th>
            <th scope="col" @click="switchSort('offerid')" >Offer Id <font-awesome-icon :icon="['fad', 'sort-alt']" v-if="sortThis.includes('offerid')" :class="sortThis.includes('Down') && `fa-rotate-180`"/></th>
            <th scope="col" @click="switchSort('orderid')" >Order Id <font-awesome-icon :icon="['fad', 'sort-alt']" v-if="sortThis.includes('orderid')" :class="sortThis.includes('Down') && `fa-rotate-180`"/></th>
            <th scope="col" @click="switchSort('customerid')" >Customer ID <font-awesome-icon :icon="['fad', 'sort-alt']" v-if="sortThis.includes('customerid')" :class="sortThis.includes('Down') && `fa-rotate-180`"/></th>
            <th scope="col" @click="switchSort('sku')" >SKU <font-awesome-icon :icon="['fad', 'sort-alt']" v-if="sortThis.includes('sku')" :class="sortThis.includes('Down') && `fa-rotate-180`"/></th>
            <th scope="col" @click="switchSort('date')" >Datum <font-awesome-icon :icon="['fad', 'sort-alt']" v-if="sortThis.includes('date')" :class="sortThis.includes('Down') && `fa-rotate-180`"/></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <Row v-for="data in filteredData" :key="data.id" :input="data.data" :id="data.id"/>
        </tbody>
      </table>
      <template v-else>
        <Loading :msg="'Daten werden geladen'"/>
      </template>
      <div v-if="filteredData && filteredData.length === 0" class="w-100 text-center">Kein ergebniss</div>
    </div>
  </div>

</template>
<script>
import { getFirestore, query, collection, doc, onSnapshot, where } from "firebase/firestore";
import Row from "../components/RowComponent.vue"
import Loading from "../components/Loading.vue"
import moment from 'moment-timezone'

export default {
  name: "LoggedInView",
  components: { 
    Loading,
    Row
  },
  data(){
    return {
      data: null,
      stopLoadData: null,
      stopLoadUpdates: null,
      updates: {
        si: null,
        we: null
      },
      sortThis: 'dateUp',
      searchData: '',
      useSearch: '',
      date: [moment().startOf('year').toDate(), moment().toDate()],
    }
  },
  computed: {
    filteredData() {
      let search = this.useSearch
      let sort = this.sortThis
      let data = this.data

      data = data && search != '' ? data.filter(obj=>{
        let check = false
        let each = Object.values(obj.data)

        search = search.replace(',', '.')
        each.forEach(row => {
          String(row).toLowerCase().includes(String(search.toLowerCase())) && (check = true)
        })
        obj.data?.summaryData && Object.values(obj.data.summaryData).forEach(row => {
          String(row).toLowerCase().includes(String(search.toLowerCase())) && (check = true)
        })
        return check
      }) : data


      data && sort === 'dateUp' &&  data.sort((a,b)=>{
        const aDate = a.data?.date ? a.data?.date.toMillis() : 0
        const bDate = b.data?.date ? b.data?.date.toMillis() : 0
        return !aDate ? 0 : bDate - aDate
      });

      data && sort === 'dateDown' &&  data.sort((b,a)=>{
        const aDate = a.data?.date ? a.data.date.toMillis() : 0
        const bDate = b.data?.date ? b.data.date.toMillis() : 0
        return !aDate ? 0 : bDate - aDate
      });

      data && sort === 'singlepriceUp' &&  data.sort((a,b)=>{
        const aData = a.data?.singlePrice ? a.data.singlePrice : 0
        const bData = b.data?.singlePrice ? b.data.singlePrice : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'singlepriceDown' &&  data.sort((b,a)=>{
        const aData = a.data?.singlePrice ? a.data.singlePrice : 0
        const bData = b.data?.singlePrice ? b.data.singlePrice : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'quantityUp' &&  data.sort((a,b)=>{
        const aData = a.data?.quantity ? a.data.quantity : 0
        const bData = b.data?.quantity ? b.data.quantity : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'quantityDown' &&  data.sort((b,a)=>{
        const aData = a.data?.quantity ? a.data.quantity : 0
        const bData = b.data?.quantity ? b.data.quantity : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'calcCostUp' &&  data.sort((a,b)=>{
        const aData = a.data?.kalk_kosten_total ? a.data.kalk_kosten_total : 0
        const bData = b.data?.kalk_kosten_total ? b.data.kalk_kosten_total : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'calcCostDown' &&  data.sort((b,a)=>{
        const aData = a.data?.kalk_kosten_total ? a.data.kalk_kosten_total : 0
        const bData = b.data?.kalk_kosten_total ? b.data.kalk_kosten_total : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'sPriceUp' &&  data.sort((a,b)=>{
        const aData = a.data?.summaryData?.['Product Price'] ? a.data.summaryData?.['Product Price'] : 0
        const bData = b.data?.summaryData?.['Product Price'] ? b.data.summaryData?.['Product Price'] : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'sPriceDown' &&  data.sort((b,a)=>{
        const aData = a.data?.summaryData?.['Product Price'] ? a.data.summaryData?.['Product Price'] : 0
        const bData = b.data?.summaryData?.['Product Price'] ? b.data.summaryData?.['Product Price'] : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'sqtyUp' &&  data.sort((a,b)=>{
        const aData = a.data?.summaryData?.['Quantity'] ? a.data.summaryData?.['Quantity'] : 0
        const bData = b.data?.summaryData?.['Quantity'] ? b.data.summaryData?.['Quantity'] : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'sqtyDown' &&  data.sort((b,a)=>{
        const aData = a.data?.summaryData?.['Quantity'] ? a.data.summaryData?.['Quantity'] : 0
        const bData = b.data?.summaryData?.['Quantity'] ? b.data.summaryData?.['Quantity'] : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'sExPriceUp' &&  data.sort((a,b)=>{
        const aData = a.data?.summaryData?.['Ext Price'] ? a.data.summaryData?.['Ext Price'] : 0
        const bData = b.data?.summaryData?.['Ext Price'] ? b.data.summaryData?.['Ext Price'] : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'sExPriceUp' &&  data.sort((b,a)=>{
        const aData = a.data?.summaryData?.['Ext Price'] ? a.data.summaryData?.['Ext Price'] : 0
        const bData = b.data?.summaryData?.['Ext Price'] ? b.data.summaryData?.['Ext Price'] : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });
      
      data && sort === 'offeridUp' &&  data.sort((a,b)=>{
        const aData = a.data?.vipmp_offer_id ? a.data.vipmp_offer_id : 0
        const bData = b.data?.vipmp_offer_id ? b.data.vipmp_offer_id : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'offeridUp' &&  data.sort((b,a)=>{
        const aData = a.data?.vipmp_offer_id ? a.data.vipmp_offer_id : 0
        const bData = b.data?.vipmp_offer_id ? b.data.vipmp_offer_id : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });
      
      data && sort === 'orderidUp' &&  data.sort((a,b)=>{
        const aData = a.data?.orderId ? a.data.orderId : 0
        const bData = b.data?.orderId ? b.data.orderId : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'orderidUp' &&  data.sort((b,a)=>{
        const aData = a.data?.orderId ? a.data.orderId : 0
        const bData = b.data?.orderId ? b.data.orderId : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'customeridUp' &&  data.sort((a,b)=>{
        const aData = a.data?.customerId ? a.data.customerId : 0
        const bData = b.data?.customerId ? b.data.customerId : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'customeridUp' &&  data.sort((b,a)=>{
        const aData = a.data?.customerId ? a.data.customerId : 0
        const bData = b.data?.customerId ? b.data.customerId : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'skuUp' &&  data.sort((a,b)=>{
        const aData = a.data?.sku ? a.data.sku : 0
        const bData = b.data?.sku ? b.data.sku : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      data && sort === 'skuUp' &&  data.sort((b,a)=>{
        const aData = a.data?.sku ? a.data.sku : 0
        const bData = b.data?.sku ? b.data.sku : 0
        return !aData ? 0 : aData > bData ? -1 : 1;
      });

      return data
    }
  },
  methods: {
    async loadData(){
      const updatesSiQuery = await query(doc(getFirestore(), "updates", "si"));
      const updatesWeQuery = await query(doc(getFirestore(), "updates", "we"));
      const ordersQuery = await query(collection(getFirestore(), "orders"), where("date", ">=", this.date[0]), where("date", "<=", this.date[1]));

      this.stopLoadData = onSnapshot(ordersQuery, (qSnap) =>{
        let list = []
        qSnap.forEach((doc) => {
          
          list.push({id: doc.id, data: doc.data()})
        })
        this.data = list
      })

      this.stopLoadUpdatesSi = onSnapshot(updatesSiQuery, (snap) =>{
        this.updates.si = snap.data().date
      })

      this.stopLoadUpdatesWe = onSnapshot(updatesWeQuery, (snap) =>{
        this.updates.we = snap.data().date
      })
    },
    switchSort(value){
      if(this.sortThis.includes(value+'Up')){
        this.sortThis = value+'Down'
      } else if(this.sortThis.includes(value+'Down')){
        this.sortThis = ''
      } else {
        this.sortThis = value+'Up'
      }
    },
    reloadDate(){
      this.stopLoadData()
      this.loadData()
    }
  },
  async mounted(){
    await this.loadData()
  },
  unmounted(){
    this.stopLoadData()
    this.stopLoadUpdatesSi()
    this.stopLoadUpdatesWe()
  }
}
</script>

<style>
  .custombox {
    border: 1px #ced4da solid;
    border-radius: .25rem;
  }
  input:focus {
    outline: none !important;
  }
</style>