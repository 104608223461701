<template>
  <div class="container mt-5">
    <div class="col">
      <div class="input-group mb-3" v-if="!loading && !done">
        <input type="file" accept=".xlsx" class="form-control" id="upload" @change="async (e)=>{loadData(e.target.files[0])}">
      </div>
      <div class="progress" v-if="loading && !done">
        <div class="progress-bar" role="progressbar" :style="`width: ${loading}%`" aria-valuemax="100">{{loading}}%</div>
      </div>
      <div class="alert alert-success" v-if="done">
        <p>Upload Abgeschlossen</p>
      </div>
    </div>
  </div>
</template>

<script>
import { read, utils } from 'xlsx'
import { firestore } from "../plugins/firebase";
import { doc, getDoc, setDoc, collection, where, query, getDocs, updateDoc, addDoc } from "firebase/firestore";
import moment from 'moment-timezone';

export default {
  name: "UploadView",
  data(){
    return{
      done: false,
      progress: true,
      fileReader: new FileReader(),
      convertedUpload: false,
      loading: 0
    }
  },
  methods:{
    returnData(x){
      return new Promise((resolve, reject) => {
        this.fileReader.onload = function(e) { 
          const rawData = resolve( read(e.target.result, {type: 'binary'}))
          reject(rawData)
        }
        this.fileReader.readAsBinaryString(x)
      })
    },
    async uploadData(data){
      const length = data.length
      let thisId = 0
      for await (let row of data){
        const id = row['Order Number'] + '-' + row['SKU']
        const docRef = await doc(firestore, "summaryInvoice", id);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          await setDoc(doc(firestore, "summaryInvoice", id), row);
        }
        thisId++
        this.loading = (thisId*100/length).toFixed(0)
      }
    },
    async uploadDataAlt(data){
      const length = data.length
      let thisId = 0
      for await (let row of data){
          const fixedOfferId = this.replaceLvl(row['SKU'])
          const q = await query(collection(firestore, "orders"), where("orderId", "==", row['Order Number']), where("vipmp_offer_id_fixed", "==", fixedOfferId));
          const querySnapshot = await getDocs(q);
          !querySnapshot.empty && querySnapshot.forEach(async d => {
            //TODO: Hier nun daten zu Order hinzufügen
            !d.data().summaryData && await updateDoc(doc(firestore, "orders", d.id), {summaryData: row});
          })
          querySnapshot.empty && addDoc(collection(firestore, "orders"), {orderId: row['Order Number'], vipmp_offer_id_adobe: row['SKU'], vipmp_offer_id_fixed:fixedOfferId  , summaryData: row, date:  moment(row['Order Date']).toDate()});

        thisId++
        this.loading = (thisId*100/length).toFixed(0)
      }
      updateDoc(doc(firestore, "updates", 'si'), {date: new Date()});

    },
    async loadData(data){
      this.progress = true
      const raw = await this.returnData(data)
      this.convertedUpload = await utils.sheet_to_json(raw.Sheets[Object.keys(raw.Sheets)[0]], {defval:""})
      await this.uploadDataAlt(this.convertedUpload)
      this.progress = false
      this.done = true
      return 
    },
    replaceLvl(sku){
      let arrayedSKU = sku.split('')
      arrayedSKU[sku.length - 4] = 'X'
      return arrayedSKU.join('')
    }
  }
}
</script>

<style>

</style>