<template>
  <div style="text-align: center;">
    <pulse-loader :color="'rgba(64, 137, 201, 1)'" :size="size"></pulse-loader>
    <p v-if="msg && msg != 'false'">{{msg}}</p>
    <p v-else-if="msg != 'false'">Loading...</p>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'LoadingCircle',
  components: {
    PulseLoader
  },
  props:{
    msg: String,
    size: String
  },
  data(){
    return{
      
    }
  },
  methods:{

  },
  mounted(){

  },
}
</script>

<style>

</style>
