<template>
  <tr :class="`table-${rowColor}`">
    <td>{{input?.store}}</td>
    <td>{{input?.type}}</td>
    <td>{{negativ}}{{input?.singlePrice && input?.singlePrice.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
    <td>{{input?.quantity}}</td>
    <td>{{negativ}}{{input?.kalk_kosten_total && input?.kalk_kosten_total.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
    <td>{{input?.summaryData?.['Product Price'] && input.summaryData['Product Price'].toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
    <td>{{input?.summaryData?.['Quantity']}}</td>
    <td>{{input?.summaryData?.['Ext Price'] && input.summaryData['Ext Price'].toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</td>
    <td><span :style="`color: ${(input?.singlePrice < input?.summaryData?.['Product Price']) ? 'red' : 'green'}`">{{input?.singlePrice < input?.summaryData?.['Product Price'] ? "-" : ""}}{{differeceCalced.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</span></td>
    <td>{{input?.vipmp_offer_id ? input?.vipmp_offer_id : input?.vipmp_offer_id_adobe}}</td>
    <td>{{input?.orderId}}</td>
    <td>{{input?.customerId ? input?.customerId : input?.summaryData?.['Ship To Customer ID']}}</td>
    <td>{{input?.sku}}</td>
    <td>{{input.date && input.date.toDate().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day:'2-digit' })}}</td>
    <td style="max-width: 200px;"><span class="clickable" @click="modal.show()">{{input?.comment}}</span><button v-if="!input?.comment" class="btn btn-sm btn-secondary" @click="modal.show()">Kommentar</button></td>
    <td style="max-width: 200px;"><span class="clickable" @click="modalCheck.show()"></span><button v-if="!input?.comment" class="btn btn-sm btn-secondary" @click="modalCheck.show()">?!</button></td>
  </tr>

  <div class="modal" tabindex="-1" ref="modal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
        </div>
        <ul style="list-style: none;">
          <li v-if="input?.singlePrice"><b>Einzelpreis WE:</b> {{input?.singlePrice.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</li>
          <li v-if="input?.quantity"><b>Menge WE:</b> {{input?.quantity}}</li>
          <li v-if="input?.kalk_kosten_total"><b>Gesamtpreis WE:</b> {{input?.kalk_kosten_total.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</li>
          <li v-if="input?.summaryData?.['Product Price']"><b>Einzelpreis SI:</b> {{input.summaryData['Product Price'].toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</li>
          <li v-if="input?.summaryData?.['Quantity']"><b>Menge SI:</b> {{input?.summaryData?.['Quantity']}}</li>
          <li v-if="input?.summaryData?.['Ext Price']"><b>Gesamtpreis SI:</b> {{input.summaryData['Ext Price'].toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</li>
          <li v-if="input?.vipmp_offer_id"><b>Offer ID:</b> {{input?.vipmp_offer_id}}</li>
          <li v-if="input?.vipmp_offer_id"><b>Offer ID - Adobe:</b> {{input?.vipmp_offer_id_adobe}}</li>
          <li v-if="input?.orderId"><b>Order ID:</b> {{input?.orderId}}</li>
          <li v-if="input?.customerId"><b>Kundennummer:</b> {{input?.customerId}}</li>
          <li v-if="input?.sku"><b>SKU</b> {{input?.sku}}</li>
          <li v-if="input.date"><b>Datum:</b> {{input.date.toDate().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day:'2-digit' })}}</li>
          <li>{{input?.comment}}</li>
        </ul>
        <span class="text-center">
          <p>Status</p>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-warning" @click="updateSate('warning')">Abweichung &lt; 5€</button>
            <button type="button" class="btn btn-danger" @click="updateSate('danger')">Abweichung &gt; 5€</button>
            <button type="button" class="btn btn-default" @click="updateSate('default')">Kein Match</button>
            <button type="button" class="btn btn-orange" @click="updateSate('orange')">Kein Match</button>
            <button type="button" class="btn btn-success" @click="updateSate('success')">OK</button>
            <button type="button" class="btn btn-secondary" @click="updateSate('')">Löschen</button>
          </div>
        </span>
        <div class="modal-body" >
          <textarea class="form-control" aria-label="With textarea" v-model="comment"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" style="color: white;" @click="update()">Schließen</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" tabindex="-1" ref="modalCheck">
    <div class="modal-dialog modal-xl">
      <div class="modal-content p-1">
        <div class="modal-header">
          Daten Prüfen
        </div>
          Startdatum: {{input?.summaryData?.['Charge Start Date']}}<br>
          Enddatum: {{input?.summaryData?.['Charge End Date']}}<br>
          Tage differenz: {{calcDifferece(input?.summaryData?.['Charge Start Date'], input?.summaryData?.['Charge End Date'])}}<br>
          <br>
          Preis pro Tag laut Adobes SI: {{(input?.summaryData?.['Product Price'] / calcDifferece(input?.summaryData?.['Charge Start Date'], input?.summaryData?.['Charge End Date'])).toLocaleString("de-DE", {minimumFractionDigits: 3, maximumFractionDigits: 4})}}<br>
          <br>
          <b>Rohdaten</b>
          <pre>{{input}}</pre>

      </div>
    </div>
  </div>

</template>

<script>
import { Modal } from "@comlinedev/bootstrap5"
import { getFirestore, updateDoc, doc } from "firebase/firestore"; 

export default {
  name: 'RowComponent',
  props:{
    input: Object,
    id: String
  },
  computed: {
    rowColor() {
      let input = this.input
      if (input?.state ) {return input.state}
      
      if(input?.summaryData && !input?.singlePrice) {return 'orange'}

      if(input?.summaryData?.['Ext Price'] != input?.kalk_kosten_total && !isNaN(input?.kalk_kosten_total) && !isNaN(input?.summaryData?.['Ext Price'])){
        let difference = input?.kalk_kosten_total - input?.summaryData?.['Ext Price']
        difference = difference < 0 ? difference*-1 : difference; 
        if(difference <= 5){
          return 'warning'
        } else {
          return 'danger'
        }
      }
      if(input?.summaryData && input?.singlePrice) {return 'success'}

      return ('default')
    }
  },
  data(){
    return{
      modal: null,
      modalCheck: null,
      comment: this.input?.comment ? this.input?.comment : '',
      differeceCalced: this.differece(),
      negativ: this.input.type === "RETURN" ? "-": ""
    }
  },
  methods:{
    update(){
      updateDoc(doc(getFirestore(), "orders", this.id), {comment: this.comment});
    },
    updateSate(state){
      updateDoc(doc(getFirestore(), "orders", this.id), {state: state});
    },
    differece(){
      if(this.input.type === "RETURN"){
        let difference = this.input?.kalk_kosten_total - (this.input?.summaryData?.['Ext Price'] *-1)
        difference = difference < 0 ? difference*-1 : difference; 
        return difference > 0 ? difference.toFixed(2) : ''
      } else{
        let difference = this.input?.kalk_kosten_total - this.input?.summaryData?.['Ext Price']
        difference = difference < 0 ? difference*-1 : difference; 
        return difference > 0 ? difference.toFixed(2) : ''
      }
    },
    calcDifferece(date1String, date2String){
      const date1 = Date.parse(date1String);
      const date2 = Date.parse(date2String);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      return (diffDays);

    }
  },
  mounted(){
    this.modal = new Modal(this.$refs.modal, {backdrop: 'static', keyboard: false})
    this.modalCheck = new Modal(this.$refs.modalCheck)
  },
}
</script>

<style scoped>
  .table-orange {
    background-color: rgb(250, 209, 132);
  }
  .clickable {
    cursor: pointer;
  }
  .btn-orange {
    background-color: rgb(250, 209, 132);
  }
</style>
