<template>
  <template v-if="user?.loggedIn !== null">
    <my-navi v-if="user?.loggedIn" /> 
    <router-view></router-view>
    <template v-if="router">
      {{router.currentRoute.name}}
    </template>
  </template>
  <template v-else>
    <Loading/>
  </template>
</template>

<script>
import MyNavi from '@/components/Navbar.vue'
import Loading from "./components/Loading.vue"
import { mapGetters } from "vuex";

export default {
  name: 'App',
  components: { 
    MyNavi,
    Loading
  },
  inject: {
    router: {
      from: '$router'
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  }
}
</script>