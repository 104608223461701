import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes.js";
import store from "../store";
import { auth } from "../firebase/index";

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

router.beforeEach(async (to) => {
  const currentUser = await store.state?.user?.loggedIn;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  let tokens = currentUser ? await auth.currentUser.getIdTokenResult() : false
  let isUser = tokens ? tokens.claims?.user : false

  if ((to.name === 'Login' || to.name === 'NotAllowed') && currentUser && isUser) return({ name: 'Home' })
  else if (to.name !== 'NotAllowed' && !isUser && currentUser && requiresAuth) return({ name: 'NotAllowed' })
  else if (requiresAuth && !currentUser) return ({ name: "Login" });
});

export default router;
