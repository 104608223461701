import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAtYORZB-nf-PvX7n4oeTUhDBWwc8SD9UY",
  authDomain: "clgmbh-summery-invoice-cf.firebaseapp.com",
  projectId: "clgmbh-summery-invoice-cf",
  storageBucket: "clgmbh-summery-invoice-cf.appspot.com",
  messagingSenderId: "259975922508",
  appId: "1:259975922508:web:1116aca210eaa2adb585e9"
};

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const firestore = getFirestore(firebase);

if (location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9098", { disableWarnings: false });
  connectFirestoreEmulator(firestore, 'localhost', 8081);
}

export { firestore, auth };
