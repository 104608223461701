<template>
  <div class="container d-flex justify-content-center align-items-center" style=" height: 100vh;">
    <div class="col-xs d-flex">
      <div class="card">
        <div class="cardInner">
          <div class="headline">
            <logo-component
              :fill="'#212529'"
              :height="'40px'"
              :width="'46px'"
              class="logo"
            >
            </logo-component>
            <h1>Summary Invoice Comparison</h1>
          </div>
          <div class="alert alert-danger mt-2" v-if="error">
            {{error}}
          </div>
          <form v-on:submit.prevent="signIn" class="needs-validation" novalidate>
            <label for="email">E-Mail-Adresse</label>
            <input id="email"  type="email" v-model="email" trim />
            <label for="passwort">Passwort</label>
            <input id="passwort" type="password" v-model="password" trim  />
            <button type="submit" :disabled="email === '' || password === ''">Anmelden</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import LogoComponent from "../components/LogoComponent.vue";
import isEmail from "validator/es/lib/isEmail";
import { auth } from '../plugins/firebase'
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";

export default {
  name: "LogInView",
  components: {
    LogoComponent,
  },
  data() {
    return {
      email: "",
      password: "",
      states: {
        email: null,
      },
      error: null
    };
  },
  methods: {
    signIn() {
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => this.$router.replace({ name: "Home" }))
        .catch((error) => {
          this.error = error
        });
    },
  },
  watch: {
    email(email) {
      if (email === "") {
        this.states.email = null;
      } else {
        this.states.email = isEmail(email);
      }
    },
  },
    mounted() {
    let token = this.$route.params.token || 0
    signInWithCustomToken(auth, token)
    .then(()=>{
      this.$router.push({name: 'Home'})
    })
    .catch(err=>{console.error(err)})
  }
};
</script>

<style scoped>
form button {
  width: 100%;
  height: 38px;
  background-color: #82b2db;
  border: none;
  color: white;
  border: 1px solid #82b2db;
  border-radius: 0.25rem;
}
[type="submit"]:not(:disabled) {
  border: 1px solid #4089c9;
  background-color: #4089c9;
}
form input {
  margin-bottom: 20px;
  padding: 0 12px;
  width: 100%;
  height: 36px;
  border: 1px solid lightgray;
  border-radius: .25rem;
  font-size: 1.05rem;
}
label {
  margin-bottom: 2px;
}
h1 {
  color: #212529;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  word-wrap: break-word;
  width: 219px;
  margin-left: 14px;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  margin: 24px;
  border-top: 5px solid #4089c9;
  border-radius: .25rem;
}
.cardInner {
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  margin: 48px;
}
.headline {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}
</style>

